<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          src="@/assets/plugins/veeva_plugin.png"
          alt="veeva logo"
          height="32"
        >
        {{ installed ? 'Update ' : 'Install' }} Veeva Plugin
      </template>
      <template #subheader>
        The Veeva Plugin allows different types of integrations such as Contact and action syncing.<br>
        To start, you need to create a Connected App in the Veeva platform.<br>
        Once the Connected App is configured, you can configure this plugin with the Client ID and Client Secret provided.<br>
        Using those credentials, we can request access to the Veeva platform using your normal Veeva User access and credentials.<br>
      </template>
    </PageHeader>

    <div class="mt-10">
      <validation-observer
        ref="inputRef"
      >
        <div class="mb-3">
          Details on how to set up connected app to be added...
        </div>
        <div>
          <CustomDropdown
            v-model="salesforceAccountType"
            header="Salesforce Account Type"
            placeholder="Select an option"
            :items="salesforceAccountTypes"
            required
          />
        </div>
        <div>
          <CustomTextInput
            v-model="consumerKey"
            header="Consumer Key"
            required
          />
        </div>
        <div>
          <CustomTextInput
            v-model="consumerSecret"
            header="Consumer Secret"
            type="password"
            required
          />
        </div>
        <div v-if="veevaUser">
          Status: <span class="veeva-authenticated">Authenticated</span><br>
          User: {{ veevaUser.display_name }}<br>
          Email: {{ veevaUser.username }}
        </div>
        <div v-else-if="authorizationUrl">
          Status: <span class="veeva-unauthenticated">Not Authenticated</span><br>
          <br>
          Pulse Engagement Cloud requires access to Veeva CRM by requesting access through Salesforce which Veeva uses as the underlying platform.<br>
          In order to enable this Veeva integration, we need a user with access to Veeva to login and allow Pulse Engagement Cloud to access the data.<br>
          <br>
          Please click the link below to login to Salesforce and allow this access:<br>
          <br>
          <a :href="authorizationUrl">Salesforce Login</a>
        </div>
        <template v-if="veevaUser">
          <hr class="my-5">
          <h3>Configuration</h3>
          <div>
            <CustomDropdown
              v-model="contactSyncOption"
              header="Contact Sync Option"
              placeholder="Select an option"
              :items="contactSyncOptions"
              required
            />
          </div>
          <div>
            <CustomDropdown
              v-model="segments"
              header="Segments to Sync"
              placeholder="Select an option"
              :items="segmentOptions"
              item-value="id"
              item-text="name"
              hint="Only contacts belonging to one of these segments will be allowed to sync to Veeva. Note: Updates to this field will not sync contacts or send action data for contacts already existing in the segment. Only new contacts and action data for contacts in these segments going forward will be sent to Veeva. "
              required
              multiple
              autocomplete
            />
          </div>
          <div>
            <CustomDropdown
              v-model="actionsToSync"
              header="Actions to Sync"
              placeholder="Select options"
              :items="actionSyncOptions"
              multiple
              required
            />
          </div>
          <div>
            <CustomTextInput
              v-model="alertNotificationRecipients"
              header="Alert Notification Recipients"
              hint="A comma separated list of email addresses that should receive an alert notification if actions fail to sync to Veeva."
            />
          </div>
        </template>
      </validation-observer>

      <v-row class="mt-12">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            v-if="installed"
            class="custom-button custom-button--red-text px-8 mr-4"
            height="34px"
            depressed
            @click="deactivateDialog = true;"
          >
            Deactivate
          </v-btn>

          <v-btn
            class="custom-button custom-button--blue px-13"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="trigger"
          >
            {{ plugin ? 'Update' : 'Install' }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="plugin"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";

export default {
  name: "AddPluginVeeva",
  metaInfo: {
    title: 'Install Veeva'
  },
  components: {
    CustomDropdown,
    CustomTextInput,
    DeactivateConfirmDialog,
    PageHeader,
  },
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    contactSyncOption: 'veeva-id',
    actionsToSync: [],
    alertNotificationRecipients: '',
    segments: [],
    segmentOptions: [],
    contactSyncOptions: [
      { label: 'Only save Veeva ID in Pulse', value: 'veeva-id' },
      { label: 'Sync IDs in both directions', value: 'bidirectional-id' },
    ],
    actionSyncOptions: [
      { label: 'Event/Conference Actions', value: 'event' },
    ],
    salesforceAccountTypes: [
      { label: 'Sandbox (test.salesforce.com)', value: 'test' },
      { label: 'Production (login.salesforce.com)', value: 'login' },
    ],
    salesforceAccountType: 'test',
    consumerKey: null,
    consumerSecret: null,
    veevaUser: null,
    authorizationUrl: null,
    authorizationCode: null,
    plugin: null,
  }),
  async created() {
    if (this.installed) {
      await this.loadAccountPlugin();

      if (this.$route.query.code) {
        this.authorizationCode = this.$route.query.code;
        const response = await this.updatePlugin(true);

        if (!response) {
          this.$store.commit('snackbar/showMessage', {
            content: 'Failed to update plugin',
            color: 'error',
          });
        } else {
          this.authorizationCode = null;
          const query = {...(this.$route.query ?? [])};
          delete query.code;
          this.$router.replace({ query });

          await this.loadAccountPlugin();
        }
      }
    }
  },
  methods: {
    async loadAccountPlugin() {
      const response = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!response.data) {
        return;
      }

      this.plugin = {...response.data};

      if (!Array.isArray(this.plugin?.pluginConfiguration?.credentials?.access)) {
        const idResp = await this.$rest.plugins.veeva.getIdentity();

        if (!Array.isArray(idResp.data)) {
          this.veevaUser = {...idResp.data};
        }
      }

      if (!this.veevaUser) {
        this.authorizationUrl = this.plugin?.pluginConfiguration?.credentials?.authorizationUrl;
      } else {
        this.authorizationUrl = null;

        await this.loadSegments();
      }

      this.consumerKey = this.plugin?.pluginConfiguration?.credentials?.consumerKey;
      this.consumerSecret = this.plugin?.pluginConfiguration?.credentials?.consumerSecret;
      this.salesforceAccountType = this.plugin?.pluginConfiguration?.salesforceAccountType;
      this.contactSyncOption = this.plugin?.pluginConfiguration?.contactSyncOption;
      this.actionsToSync = this.plugin?.pluginConfiguration?.actionsToSync;
      this.alertNotificationRecipients = this.plugin?.pluginConfiguration?.alertNotificationRecipients;
      this.segments = this.plugin?.pluginConfiguration?.segments;
    },
    async loadSegments() {
      const response = await this.$rest.segment.get_collection({
        ignorePagination: true,
        sort: ['name:asc'],
      });

      if (!response.data) {
        return;
      }

      this.segmentOptions = response.data.items.map(item => ({
        id: item.id,
        name: item.isArchived ? item.name + ' [Archived]': item.name,
      }));
    },
    trigger() {
      if (this.plugin) {
        return this.updatePlugin();
      } else {
        return this.installPlugin();
      }
    },
    getPluginConfiguration() {
      return {
        salesforceAccountType: this.salesforceAccountType,
        contactSyncOption: this.contactSyncOption,
        actionsToSync: this.actionsToSync,
        alertNotificationRecipients: this.alertNotificationRecipients,
        segments: this.segments,
        credentials: {
          ...(this.plugin?.pluginConfiguration?.credentials ?? {}),
          consumerKey: this.consumerKey,
          consumerSecret: this.consumerSecret,
          authorizationCode: this.authorizationCode,
        },
      };
    },
    async updatePlugin(returnResponse = false) {
      const pluginConfiguration = this.getPluginConfiguration();

      const response = await this.$rest.plugins.put_resource(this.id, {
        ...this.plugin,
        plugin: this.plugin.plugin.id,
        pluginConfiguration
      });
      if (!response.data) {
        if (returnResponse) {
          return null;
        }

        return this.$store.commit('snackbar/showMessage', {
          content: 'Failed to update plugin',
          color: 'error',
        });
      }

      if (returnResponse) {
        return response;
      }

      this.$store.commit('snackbar/showMessage', {
        content: 'Plugin updated successfully',
        color: 'info',
      });
      setTimeout(() => {
        this.$router.push({
          name: "Plugins",
        })
      }, 500);
    },
    async installPlugin() {
      const pluginConfiguration = this.getPluginConfiguration();

      const response = await this.$rest.plugins.post_resource({
        plugin: +this.id,
        isActive: true,
        pluginConfiguration,
      });
      if (!response.data) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Failed to install plugin',
          color: 'error',
        });
      }

      const selected_account = this.$store.getters["user/account"];
      this.$store.commit("user/update_selected_account", {
        ...selected_account,
        activePlugins: [...selected_account.activePlugins, response.data],
      });

      this.$store.commit('snackbar/showMessage', {
        content: 'Installed plugin successfully',
        color: 'info',
      });
      setTimeout(() => {
        this.$router.push({
          name: "Plugins",
        })
      }, 500);
    },
  },
}
</script>

<style scoped>
.veeva-unauthenticated {
  color: darkred;
  font-weight: bold;
}
.veeva-authenticated {
  color: green;
  font-weight: bold;
}
</style>
